import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
// import { mb } from 'styled-components-spacing';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Seo } from '../../components';
import { Gallery, IconsList, PropertyContact } from '../../components';

const FixedHeroImageContainer = styled.div`
  position: fixed;
  z-index: -1;
  .gatsby-image-wrapper {
    filter: brightness(0.5);
    /* max-height: 105vh; */
    width: 100vw;
    min-height: 100vh;
  }
`;

const HeroContentContainer = styled.div`
  height: max(675px, 100vh);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 2.3rem; */
  .hero-title {
    /* 42px 67px */
    font-size: 3.125rem;
    color: var(--white);
    margin: 0;
    margin-block: 3rem 2rem;
    font-family: 'Calluna';
    font-weight: 600;
    text-shadow: 0 0px 10px #b5b5b5;
    text-align: center;
    width: 95%;
  }

  .comm-hero-title {
    margin-block: 4.5rem 2rem;
  }

  @media screen and (max-width: 480px) {
    .hero-title {
      /* 280-480 40-50px */
      font-size: clamp(2.5rem, calc(1.63rem + 5vw), 3.13rem);
      margin-top: 12rem;
    }
    .comm-hero-title {
      margin-top: 2rem;
    }
  }
  @media screen and (max-width: 277px) {
    .comm-hero-title {
      margin-top: 6rem;
    }
  }
  @media screen and (min-width: 718px) {
    .hero-title {
      /* 718-1200 50-67px */
      font-size: clamp(3.13rem, calc(1.54rem + 3.53vw), 4.19rem);
    }
  }
  @media screen and (min-width: 400px) and (max-width: 693px) {
    .hero-title {
      margin-top: 6.7rem;
    }
    .comm-hero-title {
      margin-top: 2rem;
    }
  }
`;

const ContentWrapper = styled.section`
  background: var(--white);
  z-index: 2;
`;

const ContentContainer = styled.div`
  width: min(1150px, 90%);
  margin: 0 auto;
  padding-top: 120px;
  display: flex;
  gap: 4%;

  @media screen and (max-width: 650px) {
    width: 85%;
    flex-direction: column;
    gap: 5rem;
  }

  @media screen and (max-width: 340px) {
    padding-inline: 0.375rem;
  }

  @media screen and (min-width: 1440px) {
    width: min(1350px, 80%);
  }
`;

const Column1 = styled.div`
  /* flex grow shrink basis */
  flex: 1 0 22%;
  min-width: 200px;
  .spec:not(:last-of-type) {
    margin-bottom: 50px;
  }
  .col-1-heading {
    font-family: 'Poppins';
    letter-spacing: 1px;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 90px;
  }
  .col-1-subheading {
    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    color: #19191969;
    color: #2994fdc9;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .col-1-details {
    font-size: 1rem;
    color: var(--darkBlack);
  }

  @media screen and (max-width: 650px) {
    display: flex;
    flex-wrap: wrap;
    .col-1-heading {
      width: 50%;
      /* flex-grow: 1; */
    }
    .spec {
      width: 50%;
      /* text-align: center; */
    }
  }

  @media screen and (max-width: 350px) {
    display: flex;
    flex-direction: column;
  }
`;

const Column2 = styled.div`
  width: 100%;
  position: relative;
  margin-left: min(3vw, 2rem);
  .title-address {
    margin: 0;
    letter-spacing: 0;
  }
  .col-2-heading {
    font-family: 'Poppins';
    letter-spacing: 1px;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 80px;
  }
  .circle-wrapper {
    position: absolute;
    /* top: -7px;
    left: 170px; */
    top: 25px;
    left: 0px;
    .circle {
      color: var(--lightBlue);
      font-size: 1.75rem;
      &:first-of-type {
        color: var(--secondary);
        padding-right: 0.6rem;
      }
    }
  }
  .property-details {
    /* accts for line-height offset space */
    margin-top: -0.2em;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      -webkit-letter-spacing: 0px;
      -moz-letter-spacing: 0px;
      -ms-letter-spacing: 0px;
      letter-spacing: 0px;
      margin: 0;
      padding: 0.5rem 0;
      line-height: 140%;
      color: var(--darkBlack);
    }
    .details-description {
      padding: 1em 0;
      margin: 0;
    }
    .details-video-container {
      padding: 1rem 0;
      display: inline-block;
      width: min(50vw, 560px);
      .details-video {
        /* width="560" height="315" */
        height: 100%;
        width: 100%;
      }
    }

    .youtube-container {
      text-align: center;
      min-height: 220px;
      max-height: 315px;
      height: 32vw;
    }
    .details-image-container {
      padding: 1rem 0;
      .gatsby-image-wrapper {
        max-width: 560px;
      }
    }
    *:first-child:not(.details-video-container) {
      padding-top: 0;
    }
  }

  @media screen and (max-width: 900px) {
    .property-details {
      /* set font size in container so that child heading elements with em font sizes and adjust dynamically */
      font-size: 22px;
    }
  }

  @media screen and (max-width: 650px) {
    margin-left: 0;
    .property-details {
      .details-video-container {
        padding: 1rem 0;
        display: inline-block;
        width: min(440px, 100%);
      }
      .youtube-container {
        height: 60vw;
      }
    }
  }
`;

const GalleryContainer = styled.div`
  /* background-color: #fffcfa; */
  background-color: #f9f7f7;
  margin-top: 60px;
  padding-block: 60px;
`;

const ContactContainer = styled.div`
  padding-top: 60px;
  .contact-heading-wrapper {
    width: min(1150px, 90%);
    margin: 0 auto;
    .contact-heading {
      font-family: Poppins Montserrat, sans-serif;
      letter-spacing: 1px;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 14px;
      text-align: center;
    }
    .contact-subtitle {
      text-align: center;
      letter-spacing: 0.3px;
      font-size: 18px;
      line-height: 1.944;
    }
  }

  @media screen and (max-width: 550px) {
    .contact-heading-wrapper,
    .recaptcha-branding {
      width: min(85%, 425px);
      margin: auto;
    }
  }
`;

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => {
      const isEmptyChildren = children?.toString().trim() === '';
      // console.log(children);
      if (isEmptyChildren) return null;

      return <p className="details-description">{children}</p>;
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      console.log(node);
    },
    [BLOCKS.EMBEDDED_ASSET]: node => {
      // console.log(node);
      const asset = node.data.target;
      // image/jpeg
      const contentType = asset.file.contentType.split('/');
      switch (contentType[0]) {
        case 'video':
          return (
            <div className="details-video-container">
              <video controls playsInline className="details-video">
                <source src={asset.file.url} type="video/mp4" />
                <track
                  kind="captions"
                  srcLang="en"
                  src={asset.description}
                ></track>
                Sorry, your browser doesn't support embedded videos or the HTML5
                video tag, but don't worry, you can{' '}
                <a href={asset.file.url}>download it</a>
                and watch it with your favorite video player!
              </video>
              <p style={{ textAlign: 'center' }}>{asset.description}</p>
            </div>
          );
        case 'image':
          const gImage = getImage(asset.gatsbyImageData);
          return (
            <div className="details-image-container">
              <GatsbyImage image={gImage} alt={asset.description} />
            </div>
          );
        default:
          return;
      }
    },
    [INLINES.HYPERLINK]: node => {
      // console.log(node);
      if (node.data.uri.includes('youtube')) {
        return (
          <span className="details-video-container youtube-container">
            <iframe
              // width="560"
              // height="315"
              src={node.data.uri}
              title={node.content[0].value}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="details-video"
            ></iframe>
            <br />
            <span>{node.content[0].value}</span>
          </span>
        );
      }
    }
  }
};

const PropertyTemplate = ({ data: { property }, pageContext }) => {
  // console.log(property);
  // fixes issue where clicking gatsby internal links would not reset scroll position to top of page
  useEffect(() => {
    if (typeof window !== undefined) {
      window.scrollTo(0, 0);
    }
  }, []);

  const {
    address,
    acquisitionDate,
    bathrooms,
    bedrooms,
    city,
    details,
    highlights,
    images,
    dateSold,
    purchasedPrice,
    icon,
    kitchens,
    zipCode,
    price,
    propertyType,
    rooms,
    squareFt,
    status,
    title,
    thumbnail,
    type,
    zone
  } = property;
  const propertySpecs = [
    { subtitle: 'status', value: status },
    { subtitle: 'neighborhood', value: city },
    { subtitle: 'zone', value: zone },
    { subtitle: 'type', value: type },
    { subtitle: 'Property Type', value: propertyType },
    { subtitle: 'Purchased Price', value: purchasedPrice },
    { subtitle: 'Renovated Price', value: price },
    { subtitle: 'Acquisition Date', value: acquisitionDate },
    { subtitle: 'Date Sold', value: dateSold || 'Pending' },
    { subtitle: 'Highlights', value: highlights }
  ];

  const gImage = getImage(thumbnail);
  return (
    <section>
      <Seo title={`${title} | Portfolio`} />
      <FixedHeroImageContainer>
        <GatsbyImage image={gImage} alt={thumbnail.description} />
      </FixedHeroImageContainer>
      <HeroContentContainer>
        {/* title can be any name for the property ex. Madison Square Garden */}
        <h1
          className={[
            'hero-title',
            type === 'Commercial' ? 'comm-hero-title' : ''
          ].join(' ')}
        >
          {title}
        </h1>
        <IconsList
          type={type}
          commercial={{ iconName: icon, commSquareFt: squareFt }}
          residential={{ rooms, bedrooms, bathrooms, kitchens, squareFt }}
        />
      </HeroContentContainer>
      <ContentWrapper>
        <ContentContainer>
          <Column1>
            <h4 className="col-1-heading">Overview</h4>
            {/* only show if value for subtitle exists (i.e. not inputting any highlights results in spec.value: null) */}
            {propertySpecs.map((spec, i) => (
              <div key={`${spec}-${i}`} className="spec">
                {spec.value && (
                  <h3 className="col-1-subheading">{spec.subtitle}</h3>
                )}
                {spec.subtitle !== 'Highlights' ? (
                  <p className="col-1-details">{spec.value}</p>
                ) : (
                  spec.value?.map((highlight, i) => (
                    <p className="col-1-details" key={`${highlight}-${i}`}>
                      {highlight}
                    </p>
                  ))
                )}
              </div>
            ))}
          </Column1>
          <Column2>
            <h4 className="col-2-heading">About The Space</h4>
            <div className="circle-wrapper">
              <span className="circle">&#8226;</span>
              <span className="circle">&#8226;</span>
            </div>
            <section className="property-details">
              {details && renderRichText(details, options)}
            </section>
          </Column2>
        </ContentContainer>
        <GalleryContainer>
          <Gallery images={[{ ...thumbnail }, ...images]} />
        </GalleryContainer>
        <ContactContainer>
          <div className="contact-heading-wrapper">
            <h4 className="contact-heading">Get In Touch</h4>
            <h5 className="contact-subtitle">
              Interested in learning more about{' '}
              <span style={{ display: 'inline-block' }}>{address}</span>? <br />
              Feel free to drop us an{' '}
              <a
                style={{ color: 'var(--buttonBlue)' }}
                href="mailto:pkhan@khancapitalgroup.com"
              >
                email
              </a>{' '}
              or fill the form below. We'd love to connect with you.
            </h5>
          </div>
          <PropertyContact zipCode={zipCode} address={address} />
        </ContactContainer>
      </ContentWrapper>
    </section>
  );
};

// when gatsby sets pages programmatically, it makes query variables available
// id and slug are automatically accessible here from the pageContext property in props
export const query = graphql`
  query getProperty($id: String) {
    property: contentfulProperty(id: { eq: $id }) {
      address
      acquisitionDate(formatString: "MMMM DD, YYYY")
      dateSold(formatString: "MMMM DD, YYYY")
      bathrooms
      bedrooms
      city
      details {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData
            description
            file {
              contentType
              url
            }
          }
        }
      }
      kitchens
      highlights
      price
      purchasedPrice
      icon
      propertyType
      squareFt
      status
      zipCode
      title
      rooms
      type
      zone
      images {
        gatsbyImageData
        description
      }
      thumbnail {
        gatsbyImageData(layout: FULL_WIDTH)
        description
      }
    }
  }
`;

export default PropertyTemplate;
